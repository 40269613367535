import colors from "src/assets/styles/colors.js";
import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const BigParagraph = styled.p`
  font-size: ${typographySizes.l}rem;
  line-height: ${typographySizes.lh};
  color: var(--rest);
  margin-bottom: 50rem;
  /* text-transform: uppercase; */
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.m}rem;
    line-height: ${typographySizes.mh};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}rem;
    line-height: ${typographySizes.smh};
  }
`;
export default BigParagraph;
