import React, { useRef, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import main from "src/assets/styles/main.js";

import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import Body from "src/components/global/Typo/Body.js";
import Footer from "src/components/global/Footer.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import Img from "gatsby-image";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";

import Testimonial from "src/components/global/Testimonial.js";
import Awards from "src/components/about/Awards.js";
import Clients from "src/components/about/Clients.js";
import Title from "src/components/global/Typo/BigTitle.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";
import Slider from "src/components/about/Slider.js";
import Team from "src/components/about/Team.js";
import ReelPlayer from "src/components/global/ReelPlayer.js";
import TopSection from "src/components/about/TopSectionSimple.js";
import Services from "src/components/about/Services.js";

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.pageMarginSmall}rem;
  }
`;
const AboutImg = styled(Img)`
  width: 100%;
  margin-top: 50rem;
  overflow: hidden;
  border-radius: ${main.radius}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    /* order: 3; */
    display: none;
  }
`;

const About = ({ data, pageContext }) => {
  // useInterval(() => {
  //   if (isBrowser) {
  //     if (document.body.getBoundingClientRect().top > scrollTop) {
  //       // setRDist(scrollTop * speedR);
  //       setLDist(scrollTop * speedL);
  //       setScrollTop(document.body.getBoundingClientRect().top);
  //       // console.log(scrollTop * speedL);
  //     } else {
  //       // setRDist(scrollTop * speedR);
  //       setLDist(scrollTop * speedL);
  //       // console.log(scrollTop * speedL);
  //       setScrollTop(document.body.getBoundingClientRect().top);
  //     }
  //   }
  // }, 1);

  // function useInterval(callback, delay) {
  //   const savedCallback = useRef();

  //   // Remember the latest callback.
  //   useEffect(() => {
  //     savedCallback.current = callback;
  //   }, [callback]);

  //   // Set up the interval.
  //   useEffect(() => {
  //     function tick() {
  //       savedCallback.current();
  //     }
  //     if (delay !== null) {
  //       let id = setInterval(tick, delay);
  //       return () => clearInterval(id);
  //     }
  //   }, [delay]);
  // }
  const [openVid, setOpenVid] = useState(false);

  const closeFn = () => {
    setOpenVid(false);
  };
  return (
    <Wrapper>
      <Seo
        title={data.strapiPageAbout.Seo && data.strapiPageAbout.Seo.Title}
        description={
          data.strapiPageAbout.Seo && data.strapiPageAbout.Seo.Description
        }
        image={
          data.strapiPageAbout.Seo &&
          data.strapiPageAbout.Seo.Img.localFile.publicURL
        }
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
      />

      <TopSection
        title={data.strapiPageAbout.Title}
        // smallTextTop={data.strapiPageAbout.Small_text_top}
        topImg={data.strapiPageAbout.Top_img.localFile.childImageSharp.fluid}
        bigParagraph={data.strapiPageAbout.Big_paragraph_top}
        locale={pageContext.locale}
        // teamImages={data.strapiPageAbout.Team_images}
        reel={data.strapiPageAbout.Showreel_thumbnail.localFile.publicURL}
        smallParagraph={data.strapiPageAbout.Small_paragraph_top}
      />
      <Services
        services={data.strapiPageAbout.Brand_Web}
        title={data.strapiPageAbout.Brand_Web_title}
      />
      <MainWrapper>
        <AboutImg
          loading="eager"
          fluid={data.strapiPageAbout.Top_img.localFile.childImageSharp.fluid}
        />
      </MainWrapper>
      <Team
        team={data.allStrapiTeam.edges}
        title={data.strapiPageAbout.Team_title}
      />
      <Clients
        clients={data.strapiPageAbout.Client}
        title={data.strapiPageAbout.Client_title}
      />
      <Testimonial
        lang={pageContext.locale}
        testimonials={data.allStrapiTestimonial}
      />
      <Awards
        lang={pageContext.locale}
        awards={data.allStrapiAward.edges}
        bigTitle={data.strapiPageAbout.Awards_big_title}
        smallTitle={data.strapiPageAbout.Awards_small_title}
      />
      <Footer
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default About;

export const IndexQuery = graphql`
  query About($locale: String!) {
    strapiPageAbout(locale: { eq: $locale }) {
      Seo {
        Title
        Description
        Img {
          localFile {
            publicURL
          }
        }
      }
      Big_paragraph_top
      Page_name
      Small_paragraph_top

      Title
      Top_img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      localizations {
        data {
          attributes {
            locale
            Page_name
          }
        }
      }

      Showreel {
        localFile {
          publicURL
        }
      }
      Showreel_thumbnail {
        localFile {
          publicURL
        }
      }

      Client_title

      Client {
        Text
        Logo {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      Team_title
      Brand_Web_title
      Brand_Web {
        Name
        Small_text
        Top_intro
        Position {
          Description
          Name
        }
      }

      Awards_big_title
      Awards_small_title
    }
    allStrapiTeam(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          Description
          Name
          Position
          Img {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allStrapiAward(
      sort: { order: ASC, fields: Platform_name }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Project_name
          Award_thumbnail {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Link
          Platform_name
        }
      }
    }
    allStrapiTestimonial(
      sort: { order: ASC, fields: About_order }
      filter: { locale: { eq: $locale }, About_page: { eq: true } }
    ) {
      edges {
        node {
          Company_name
          Clutch
          Featured_text

          Home_page
          Person_name
          Testimonial {
            ... on STRAPI__COMPONENT_TESTIMONIAL_TEXT {
              Testimonial {
                data {
                  Testimonial
                }
              }
              strapi_component
            }
            ... on STRAPI__COMPONENT_TESTIMONIAL_VIDEO {
              Video {
                localFile {
                  publicURL
                }
              }
              strapi_component
            }
          }
          Logo {
            width
            height
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Person_img {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }

    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
