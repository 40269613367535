import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Link } from "gatsby";
import Img from "gatsby-image";
import styled, { keyframes } from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import main from "src/assets/styles/main.js";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import { gsap } from "gsap";
import Arrow from "src/assets/svg/arrow.inline.svg";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Line from "src/components/global/Line.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

const StyledMainWrapper = styled(MainWrapper)`
  margin-top: ${distances.sectionMarginBig}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.sectionMarginSmall}rem;
    margin-top: ${distances.sectionMarginSmall}rem;
  }
`;
const TitleTop = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
`;
const Title = styled(SmallTitle)`
  margin: 40rem 0;
`;
const Wrapper = styled.div``;

const ImgWrapper = styled.div`
  border-radius: ${main.radius}rem;
  background-color: var(--rest);
  width: 500rem;
  height: 0rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border-radius: ${main.radius}rem;
  overflow: hidden;
  pointer-events: none;
  transform: scale(1);
  transition: height 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.show {
    height: 300rem;
    transition: height 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const StyledImg = styled(Img)`
  width: 500rem;
  height: 300rem;
`;
const moveOverlay = keyframes`
  from {
    transform :translateY(100%)
  }
  to {
    transform :translateY(-100%)
  }
`;
const Overlay = styled.div`
  background-color: var(--rest);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);

  &.move {
    animation: ${moveOverlay} 0.4s linear;
  }
`;

const AwwardsWrapper = styled.div`
  position: relative;
`;
///
const PlatformName = styled.span`
  display: inline-block;
  width: 25%;
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  @media (max-width: ${mediaQuery.tablet}) {
    width: initial;
  }
`;

const ProjectName = styled.span`
  display: inline-block;
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  width: 75%;
  display: flex;
  justify-content: space-between;
  transform: translateX(0rem);

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    width: initial;
  }
`;

const StyledArrow = styled(Arrow)`
  .st02 {
    fill: var(--rest);
  }

  width: 13rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;
const AwwardWrapper = styled.a`
  padding: 20rem 0;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  ${PlatformName} {
    transform: translateX(0rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ${StyledArrow} {
    transform: translateX(0rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    &.leftArrow {
      position: absolute;
      transform: translateX(-100rem);
    }
  }
  :hover {
    ${PlatformName} {
      transform: translateX(50rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${StyledArrow} {
      transform: translateX(50rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      &.leftArrow {
        transform: translateX(20rem);
      }
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const moveFromTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const moveToTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;

const moveFromBottom = keyframes`
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const moveToBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
`;

const Mask = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  position: absolute;
  height: calc(100% + 1rem);
  width: 100%;
  background-color: var(--rest);
  display: flex;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  top: -1rem;

  &.top {
    animation: ${moveFromTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.bottom {
    animation: ${moveFromBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.toBottom {
    animation: ${moveToBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.toTop {
    animation: ${moveToTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  ${ProjectName} {
    color: var(--bg);
  }
  ${PlatformName} {
    color: var(--bg);
  }
  ${StyledArrow} {
    .st02 {
      fill: var(--bg);
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const Award = ({ award, index, onMouseFn }) => {
  const [direction, setDirection] = useState("");
  const [boxHover, setBoxHover] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);
  const wrapper = useRef(null);

  // const imgUrls = imgs.map((img) => img.localFile.publicURL);

  const relativeCoords = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const h = bounds.height;
    const y = e.clientY - bounds.top;
    setBoxHover(true);
    if (y > h / 2) {
      setDirection("bottom");
    } else {
      setDirection("top");
    }

    setAnimationEnded(
      setTimeout(() => {
        return true;
      }, 310)
    );
  };

  const relativeCoordsLeave = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const h = bounds.height;
    const y = e.clientY - bounds.top;
    setBoxHover(false);
    const intervalId = setInterval(() => {
      if (animationEnded) {
        if (y > h / 2) {
          setDirection("toBottom");
        } else {
          setDirection("toTop");
        }
        clearInterval(intervalId);
      }
    }, 10);
  };

  return (
    <div
      onMouseEnter={() => {
        onMouseFn(index);
      }}
    >
      <AwwardWrapper
        href={award.node.Link}
        target="_blank"
        onMouseEnter={relativeCoords}
        onMouseLeave={relativeCoordsLeave}
      >
        <StyledArrow className="leftArrow" />
        <PlatformName>{award.node.Platform_name}</PlatformName>
        <ProjectName>
          {award.node.Project_name} <StyledArrow />
        </ProjectName>
        <Mask className={direction}>
          <StyledArrow className="leftArrow" />
          <PlatformName>{award.node.Platform_name}</PlatformName>
          <ProjectName>
            {award.node.Project_name} <StyledArrow />
          </ProjectName>
        </Mask>
      </AwwardWrapper>
      <Line />
    </div>
  );
};

const Awards = ({ awards, lang, bigTitle, smallTitle }) => {
  const [onSlider, setOnSlider] = useState(false);
  const [showDrag, setShowDrag] = useState(false);
  const [curIndex, setCurIndex] = useState(0);
  const [curImgIndex, setCurImgIndex] = useState(0);

  const [mousePosX, setMousePosX] = useState();
  const [mousePosY, setMousePosY] = useState();
  const [scrollTop, setScrollTop] = useState(0);
  const [change, setChange] = useState(false);

  const boxRef = useRef();
  const swiperWrapper = useRef();

  const movecursor = (e) => {
    var boxfrom = swiperWrapper.current.getBoundingClientRect().top;
    // if (e.clientY - boxfrom < 500 && onSlider) {
    //   setShowDrag(true);
    // } else {
    //   setShowDrag(false);
    // }

    // if (onSlider) {
    gsap.to(boxRef.current, {
      duration: 0.5,
      x: mousePosX + 25,
      y: mousePosY + 25,
    });
  };

  const onEnterFn = () => {
    setOnSlider(true);
  };

  const onEnterAwardFn = (index) => {
    setCurIndex(index);
  };

  const onLeaveFn = () => {
    setOnSlider(false);
  };

  const setMousePos = (clientX, clientY) => {
    setMousePosX(clientX);
    setMousePosY(clientY);
  };
  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser) {
      window.onscroll = function (e) {
        setScrollTop(document.body.getBoundingClientRect().top);

        if (
          mousePosX > swiperWrapper.current.getBoundingClientRect().left &&
          mousePosX < swiperWrapper.current.getBoundingClientRect().right &&
          mousePosY > swiperWrapper.current.getBoundingClientRect().top &&
          mousePosY < swiperWrapper.current.getBoundingClientRect().bottom
        ) {
          setShowDrag(true);
        } else {
          setShowDrag(false);
        }
      };
    }
  }, [scrollTop]);

  useEffect(() => {
    setChange(true);

    setTimeout(() => {
      setCurImgIndex(curIndex);
    }, 200);
    setTimeout(() => {
      setChange(false);
    }, 400);
  }, [curIndex]);
  return (
    <StyledMainWrapper>
      <Wrapper
        onMouseMove={({ clientX, clientY }) => setMousePos(clientX, clientY)}
        onPointerMove={movecursor}
      >
        <TitleTop>{smallTitle}</TitleTop>
        <Line />
        <Title>{bigTitle}</Title>
        <Line />

        <AwwardsWrapper
          ref={swiperWrapper}
          onMouseEnter={onEnterFn}
          onMouseLeave={onLeaveFn}
        >
          <ImgWrapper className={onSlider && "show"} ref={boxRef}>
            <StyledImg
              loading="eager"
              fluid={
                awards[curIndex].node.Award_thumbnail.localFile.childImageSharp
                  .fluid
              }
            />
            {/* <Overlay className={change && "move"} /> */}
          </ImgWrapper>
          {awards.map((award, index) => (
            <Award onMouseFn={onEnterAwardFn} award={award} index={index} />
          ))}
        </AwwardsWrapper>
      </Wrapper>
    </StyledMainWrapper>
  );
};

export default Awards;
