import React, { useState, useRef, useEffect } from "react";

import Img from "gatsby-image";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import main from "src/assets/styles/main.js";
import distances from "src/assets/styles/distances.js";
import Title from "src/components/global/Typo/BigTitle.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";

import Line from "src/components/global/Line.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Body from "src/components/global/Typo/Body.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";
import ReelPlayer from "src/components/global/ReelPlayer.js";

const Wrapper = styled(MainWrapper)`
  margin-top: 50rem;
  /* margin-bottom: ${distances.sectionMarginBig}rem; */
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
`;

const SubTitle = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  padding-top: 80rem;
  span {
    font-size: ${typographySizes.s}rem;
    color: var(--rest);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ServTitle = styled.h3`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  padding-top: 80rem;

  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const SubTitleTest = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  padding-top: 80rem;
  span {
    font-size: ${typographySizes.s}rem;
    color: var(--rest);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ServicesNamesWrapper = styled.div`
  display: flex;
  margin-top: 30rem;
  flex-wrap: wrap;
`;

const ServicesNameWrapper = styled.button`
  display: flex;
  min-width: calc(50% - 15rem);
  margin-right: 20rem;
  cursor: pointer;
  :last-of-type {
    margin-right: 0rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    :nth-of-type(2) {
      margin-top: 30rem;
    }
  }
`;
const PosName = styled.span`
  font-size: ${typographySizes.xl}rem;
  line-height: ${typographySizes.xlh};
  color: var(--rest);
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}rem;
    font-size: 20vw;
    line-height: ${typographySizes.lh};
  }
`;
const PosNumber = styled.span`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-top: 12rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 2rem;
    margin-right: 10rem;
  }
`;

const ButtonWrapper = styled.span`
  border: solid 1rem var(--rest);
  font-size: ${typographySizes.m}rem;

  color: var(--rest);
  align-self: center;
  margin-left: 50rem;
  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  width: 166rem;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    padding: 5rem 15rem;
    margin-left: 20rem;
    width: 50rem;
    height: 18rem;
  }
`;

const ServiceWrapperInfo = styled.div`
  overflow: hidden;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  max-height: 0;
  &.open {
    max-height: ${(props) => (props.maxH ? props.maxH : 3000)}rem;

    transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.mobile {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    hr {
      margin-top: 20rem;
    }
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

const TopIntro = styled(BigParagraph)`
  margin-top: 30rem;
  width: 87.5%;
  margin-bottom: 150rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 50rem;
  }
`;

const InfoBottom = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
  }
`;

const SmallInfo = styled.p`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  width: 25%;
  margin-right: 12.5%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 80%;
    margin-bottom: 50rem;
    font-size: ${typographySizes.s}rem;
    line-height: ${typographySizes.sh};
  }
`;

const SubServicesWrapper = styled.div`
  width: 43.75%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 62.5%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const SubServiceWrapper = styled.div`
  width: 42.85%;

  margin-bottom: 50rem;
  &:nth-last-child(-n + 2) {
    margin-bottom: 0rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    &:nth-last-child(-n + 2) {
      margin-bottom: 50rem;
    }
    &:last-of-type {
      margin-bottom: 0rem;
    }
  }
`;

const SubServiceName = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  margin-bottom: 10rem;
`;

const SubServiceBody = styled(Body)`
  margin-top: 20rem;
`;

const Services = ({ services, title }) => {
  const [curServices, setCurServices] = useState(0);
  const [curServiceName, setCurServiceName] = useState(
    services[curServices].Name
  );
  const isBrowser = typeof window !== "undefined";

  const container = useRef(null);
  const [mapH, setMapH] = useState(0);
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    const height = container.current.offsetHeight;

    setMapH(height);

    // setTimeout(function () {
    //   setCurentMapH(0);
    // }, 100);
  }, [container]);

  useEffect(() => {
    setCurServiceName(services[curServices].Name);
  }, [curServices]);

  const mapClick = (change) => {
    setOpenMap(change ? false : true);
    // setCurentMapH(mapH);
    // openMap ? setCurentMapH(0) : setCurentMapH(mapH);
  };
  // onClick={mapClick}
  // className={openMap ? 'mobile' : 'openMap mobile'}
  return (
    <>
      <Wrapper>
        <SectionTitle>{title}</SectionTitle>
        <Line />
        <ServicesNamesWrapper>
          {services.map((pos, index) => (
            <>
              <ServicesNameWrapper
                onClick={() => {
                  setCurServices(index);
                  mapClick(openMap && index === curServices ? true : false);
                }}
              >
                <PosNumber>0{index + 1}</PosNumber>
                <PosName>{pos.Name}</PosName>
                <ButtonWrapper>
                  <span>
                    {openMap && index === curServices ? "less" : "more"}
                  </span>
                  <span>
                    {openMap && index === curServices ? "less" : "more"}
                  </span>
                </ButtonWrapper>
              </ServicesNameWrapper>
              <ServiceWrapperInfo
                maxH={mapH}
                className={
                  openMap && curServices == index ? "open mobile" : "mobile"
                }
                ref={container}

                // style={{
                //   height: curentMapH + "px",
                // }}
              >
                {" "}
                <SubTitle>our services – {services[index].Name}</SubTitle>
                <Line />
                <TopIntro>{services[index].Top_intro}</TopIntro>
                <InfoBottom>
                  <SmallInfo>{services[index].Small_text}</SmallInfo>
                  <SubServicesWrapper>
                    {services[index].Position.map((pos) => (
                      <SubServiceWrapper>
                        <SubServiceName>{pos.Name}</SubServiceName>
                        <Line />
                        <SubServiceBody>{pos.Description}</SubServiceBody>
                      </SubServiceWrapper>
                    ))}
                  </SubServicesWrapper>
                </InfoBottom>
              </ServiceWrapperInfo>
            </>
          ))}
        </ServicesNamesWrapper>
        <ServiceWrapperInfo className={openMap ? "open desktop" : "desktop"}>
          <ServTitle>{`our services - ${services[curServices].Name}`}</ServTitle>

          <Line />
          <TopIntro>{services[curServices].Top_intro}</TopIntro>
          <InfoBottom>
            <SmallInfo>{services[curServices].Small_text}</SmallInfo>
            <SubServicesWrapper>
              {services[curServices].Position.map((pos, index) => (
                <SubServiceWrapper>
                  <SubServiceName>{pos.Name}</SubServiceName>
                  <Line />
                  <SubServiceBody>{pos.Description}</SubServiceBody>
                </SubServiceWrapper>
              ))}
            </SubServicesWrapper>
          </InfoBottom>
        </ServiceWrapperInfo>
      </Wrapper>
    </>
  );
};

export default Services;
