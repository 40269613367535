import React, { useState, useRef, useEffect } from "react";

import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import Img from "gatsby-image";

import colors from "src/assets/styles/colors.js";

import main from "src/assets/styles/main.js";

import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Line from "src/components/global/Line.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";
import Body from "src/components/global/Typo/Body.js";

import mediaQuery from "src/assets/styles/mediaQuery.js";

const StyledMainWrapper = styled(MainWrapper)`
  margin-top: ${distances.sectionMarginBig}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const TeamWrapper = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-top: 30rem;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.desktop}) {
    flex-wrap: wrap;
  }
`;

const PersonImage = styled(Img)`
  border-radius: ${main.radius}rem;
  position: relative;
  height: 35vw;
  /* opacity: 0; */
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    height: 60vw;
  }
`;

const StyledBody = styled(Body)`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;
const PersonWrapper = styled.div`
  /* width: calc(25% - ${(distances.gap * 3) / 4}rem); */
  height: 40vw;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mediaQuery.desktop}) {
    height: 80vw;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 130vw;
  }
`;

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.hover {
    transform: scale(1.05);
  }
`;
const ProjectInfoWrapper = styled.div`
  position: absolute;
  bottom: 20rem;
  left: 20rem;
  width: calc(100% - 80rem);
  background-color: var(--rest);
  padding: 20rem;
  padding-bottom: 0%;
  border-radius: ${main.radius}rem;
  overflow: hidden;
  ${Body} {
    color: var(--bg);
  }
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.hover {
    ${StyledBody} {
      margin-bottom: 20rem;

      /* transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1); */
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    bottom: 15rem;
    left: 15rem;
    padding: 15rem;
    width: calc(100% - 60rem);
    padding-bottom: 0;
    &.hover {
      ${StyledBody} {
        margin-bottom: 15rem;
      }
    }
  }
`;
const ProjectTitle = styled.h4`
  font-size: ${typographySizes.sm}rem;
  color: var(--bg);
  margin-bottom: 20rem;
  width: calc(100% - 20rem);
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 15rem;
  }
`;
const WrapperSliderObj = styled.div`
  width: calc(25% - ${(distances.gap * 3) / 4}rem);
  overflow: hidden;
  border-radius: ${main.radius}rem;
  position: relative;
  margin-right: ${distances.gap}rem;
  margin-bottom: ${distances.gap}rem;
  :nth-of-type(4n) {
    margin-right: 0;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(50% - ${distances.gap / 2}rem);
    margin-bottom: 20rem;
    :nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 20rem;
    margin-right: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const SectionTitle = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
`;
const SlideObj = ({ position, img, name, info }) => {
  const [hover, setHover] = useState(false);
  const [newH, setNewH] = useState(null);
  const [curentH, setCurentH] = useState(null);
  const boxText = useRef();

  useEffect(() => {
    setTimeout(function () {
      const height = boxText.current.offsetHeight;
      setNewH(height);

      setCurentH(0);
    }, 100);
  }, boxText);

  const hoverFn = (hoverNew) => {
    setHover(hoverNew);
    hoverNew ? setCurentH(newH) : setCurentH(0);
  };
  return (
    <WrapperSliderObj>
      <PersonWrapper
        onMouseEnter={() => hoverFn(true)}
        onMouseLeave={() => hoverFn(false)}
      >
        <StyledImage
          className={hover && "hover"}
          loading="eager"
          fluid={img}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="tex"
        />

        <ProjectInfoWrapper className={hover && "hover"}>
          <Body>{position}</Body>

          <ProjectTitle>{name}</ProjectTitle>
          <StyledBody
            ref={boxText}
            dark
            hover={hover}
            style={{
              height: curentH + "rem",
            }}
          >
            {info}
          </StyledBody>
        </ProjectInfoWrapper>
      </PersonWrapper>
    </WrapperSliderObj>
  );
};

const Team = ({ team, title }) => {
  //

  return (
    <StyledMainWrapper>
      <SectionTitle>{title}</SectionTitle>
      <Line />
      <TeamWrapper>
        {team.map((pos) => (
          <>
            <SlideObj
              position={pos.node.Position}
              img={pos.node.Img.localFile.childImageSharp.fluid}
              name={pos.node.Name}
              info={pos.node.Description}
            />
          </>
        ))}
      </TeamWrapper>
    </StyledMainWrapper>
  );
};

export default Team;
