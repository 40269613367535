import React, { useState, useRef, useEffect } from "react";

import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import Img from "gatsby-image";

import distances from "src/assets/styles/distances.js";

import Line from "src/components/global/Line.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const StyledMainWrapper = styled(MainWrapper)`
  margin-top: ${distances.sectionMarginBig}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const StyledBigParagraph = styled(BigParagraph)`
  margin-bottom: 50rem;
  width: 81.25%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;
const LogoWrapper = styled.div`
  mix-blend-mode: exclusion;
  /* height: 300rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease;
  /* &.hide {
    opacity: 0;
    transition: opacity 0.5s ease;
  } */
`;
const BoxWrapper = styled.div`
  width: calc(25% - 15rem);
  margin: 0;
  transition: opacity 0.5s ease;
  hr:last-of-type {
    display: none;
  }
  &:nth-last-child(-n + 4) {
    hr:last-of-type {
      display: block;
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(33% - 10rem);
    &:nth-last-child(-n + 4) {
      hr:last-of-type {
        display: none;
      }
    }
    &:nth-last-child(-n + 3) {
      hr:last-of-type {
        display: block;
      }
    }
  }
`;
const ClientsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${BoxWrapper} :nth-of-type(${(props) => props.cur + 1}) {
    ${LogoWrapper} {
      opacity: 0;
    }
  }
`;

const StyledImg = styled(Img)`
  /* background-color: blue; */
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const Clients = ({ clients, title }) => {
  let initialState = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [numbers, setNumbers] = useState(initialState);
  const [newNum, setNewNum] = useState(12);
  const [curImg, setCurImg] = useState(-1);
  const [logosPerRow, setLogosPerRow] = useState(4);

  let intViewportWidth = 0;

  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }
  useEffect(() => {
    if (isBrowser && intViewportWidth < 768) {
      setLogosPerRow(3);
      initialState = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      setNumbers(initialState);
      setNewNum(9);
    }
  }, []);

  useInterval(() => {
    const newArr = numbers;
    const newIndex = Math.floor(Math.random() * numbers.length);
    let newRandomNum = Math.floor(Math.random() * clients.length);

    while (numbers.includes(newRandomNum)) {
      newRandomNum = Math.floor(Math.random() * clients.length);
    }

    setCurImg(newIndex);
    setTimeout(() => {
      newArr[newIndex] = newRandomNum;
      setNumbers(newArr);
    }, 550);
    setTimeout(() => {
      setCurImg(-100);
    }, 850);
  }, 3000);

  //
  //
  //
  //
  //

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  return (
    <StyledMainWrapper>
      <StyledBigParagraph>{title}</StyledBigParagraph>

      <ClientsWrapper cur={curImg}>
        {numbers.map((pos, index) => {
          return (
            <BoxWrapper>
              <Line />
              <LogoWrapper>
                <StyledImg
                  loading="eager"
                  fluid={clients[pos].Logo.localFile.childImageSharp.fluid}
                />
              </LogoWrapper>
              <Line />
            </BoxWrapper>
          );
        })}
      </ClientsWrapper>
    </StyledMainWrapper>
  );
};

export default Clients;
