import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import { Scrollbar } from "swiper";
import { gsap } from "gsap";
import slugify from "slugify";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import "swiper/css";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import "swiper/css/scrollbar";
import distances from "src/assets/styles/distances.js";
import main from "src/assets/styles/main.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";
import Img from "gatsby-image";
import Body from "src/components/global/Typo/Body.js";
import Button from "src/components/global/Button.js";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";
import ArrowControl from "src/assets/svg/arrow.inline.svg";

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall / 2}rem;
    margin-bottom: ${distances.sectionMarginSmall / 2}rem;
    display: block;
    position: relative;
    left: ${-distances.pageMarginSmall}rem;
    width: calc(100% + ${distances.pageMarginSmall * 2}rem);
  }
`;

const SwiperWrapper = styled.div``;

const SliderWrapper = styled.div`
  .swiper {
    height: 285rem;
  }

  .swiper-slide {
    width: initial;

    overflow: hidden;
    border-radius: ${main.radius}rem;
    height: 250rem;
    :first-of-type {
      margin-left: ${distances.pageMarginSmall}rem;
    }
    margin-left: ${distances.pageMarginSmall}rem;
  }

  .swiper-scrollbar {
    background-color: var(--rest);
    width: calc(100% - ${distances.pageMarginBig}rem - 85rem) !important;
    left: 85rem;
    height: 0.5rem !important;
    bottom: 7rem;
    cursor: pointer;
    @media (max-width: ${mediaQuery.laptop}) {
      width: calc(100% - ${distances.pageMarginBig * 2}rem - 85rem) !important;
      margin-left: ${distances.pageMarginBig}rem;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      width: calc(
        100% - ${distances.pageMarginSmall * 2}rem - 85rem
      ) !important;
      margin-left: ${distances.pageMarginSmall}rem;
    }
  }
  .swiper-scrollbar-drag {
    height: 3rem !important;

    background-color: var(--rest);
  }

  @media (max-width: ${mediaQuery.laptop}) {
    flex-wrap: wrap;
  }
`;

const StyledImage = styled(Img)`
  width: ${(props) => props.ratio && 250 / props.ratio}rem;
  height: 250rem;
`;

const ControlWrapper = styled.div`
  display: flex;

  height: 14rem;
  width: 85rem;
  position: absolute;
  left: calc((100vw) * 0.25);
  left: 0;

  bottom: 0;
  z-index: 100;
  @media (max-width: ${mediaQuery.laptop}) {
    left: ${distances.pageMarginBig}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    left: ${distances.pageMarginSmall}rem;
  }
`;

const RightArrow = styled(ArrowControl)`
  width: 13rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  .st02 {
    fill: var(--rest);
  }
  &:hover {
    transform: translateY(-3rem);
  }
`;

const LeftArrow = styled(ArrowControl)`
  width: 13rem;
  transform: rotate(180deg);
  margin-right: 20rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  .st02 {
    fill: var(--rest);
  }
  &:hover {
    transform: translateY(-3rem) rotate(180deg);
  }
`;

const Controls = () => {
  const swiper = useSwiper();

  return (
    <ControlWrapper>
      <LeftArrow onClick={() => swiper.slidePrev()} />
      <RightArrow onClick={() => swiper.slideNext()} />
    </ControlWrapper>
  );
};

const Slider = ({ slider, className }) => {
  const swiperWrapper = useRef();

  return (
    <Wrapper className={className}>
      <SliderWrapper>
        <SwiperWrapper ref={swiperWrapper}>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={0}
            modules={[Scrollbar]}
            scrollbar={{ draggable: true }}
            className="mySwiper"
            grabCursor={true}
          >
            <Controls />

            {slider.map((slidePos) => (
              <SwiperSlide>
                {" "}
                <StyledImage
                  ratio={slidePos.height / slidePos.width}
                  loading="eager"
                  fluid={slidePos.localFile.childImageSharp.fluid}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </SwiperWrapper>
      </SliderWrapper>
    </Wrapper>
  );
};

export default Slider;
