import React, { useState } from "react";

import Img from "gatsby-image";
import styled, { keyframes } from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import main from "src/assets/styles/main.js";
import distances from "src/assets/styles/distances.js";
import Title from "src/components/global/Typo/BigTitle.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";

import Line from "src/components/global/Line.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Body from "src/components/global/Typo/Body.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";
import ReelPlayer from "src/components/global/ReelPlayer.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";

const TopSectionWrapper = styled(MainWrapper)`
  @media (max-width: ${mediaQuery.tablet}) {
    /* margin-bottom: ${distances.sectionMarginSmall}rem; */
  }
`;

const AboutTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const AboutWrapper = styled.div`
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ParagraphWrapper = styled.div`
  width: 62.5%;
  margin-top: 40rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 20rem;
  }
`;

const WrapperReel = styled.div`
  width: 31.25%;
  margin-left: 6.25%;

  /* height: 200rem; */
  margin-top: 40rem;
  transform: translate3d(0, 0, 0);

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
    margin-top: 0rem;
  }
`;
const show = keyframes`
 0% { opacity: 0%; }
 100% { opacity: 100%; }
`;
const NewThing = styled.div`
  display: flex;
  justify-content: space-between;

  transform: translate3d(0, 0, 0);
  align-self: flex-start;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: column;
  }
  /* @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
    margin-top: 0rem;
  } */
`;

const ReelWrapperAbout = styled.div`
  width: 60%;
  padding-bottom: 10vw;
  position: relative;
  overflow: hidden;
  border-radius: ${main.radius}rem;
  transform: translate3d(0, 0, 0);
  align-self: flex-start;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    padding-bottom: 17vw;
    margin-top: 20rem;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 54vw;
  }
`;
const PlayButtonArrow = styled(Arrow)`
  #arrow {
    fill: var(--bg) !important;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;
const PlayButton = styled.button`
  border-radius: ${main.radius}rem;
  background-color: var(--rest);
  border: solid 1rem var(--rest);
  position: absolute;
  bottom: 10rem;
  left: 10rem;
  height: 45rem;
  width: 95rem;
  font-size: ${typographySizes.s}rem;
  color: var(--bg);
  z-index: 5;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &:hover {
    background-color: var(--bg);
    color: var(--rest);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    #arrow {
      fill: var(--rest) !important;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const Since = styled(Body)`
  align-self: flex-end;
  margin-left: 100rem;
  position: relative;
  ::before {
    content: ":";
    font-size: inherit;
    color: inherit;
    position: absolute;
    left: -100rem;
    top: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    order: 2;
    margin-top: 50rem;
    margin-left: 20rem;
    ::before {
      left: -20rem;
    }
  }
`;

const IntroText = styled(Body)`
  width: 31.25%;
  margin-top: 170rem;
  margin-left: 68.75%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50rem;
    margin-left: 0;
  }
`;

const AboutImg = styled(Img)`
  width: 100%;
  margin-top: 50rem;
  overflow: hidden;
  border-radius: ${main.radius}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    order: 3;
  }
`;

const Awards = ({
  title,
  smallTextTop,
  topImg,
  bigParagraph,
  locale,
  teamImages,
  reel,
  smallParagraph,
}) => {
  const [openVid, setOpenVid] = useState(false);

  const isBrowser = typeof window !== "undefined";

  const closeFn = () => {
    setOpenVid(false);
  };
  return (
    <>
      {" "}
      <ReelPlayer lang={locale} open={openVid} closeFn={closeFn} />
      <TopSectionWrapper>
        <AboutWrapper>
          <AboutTop>
            <Title>
              <Animate text={title} />
            </Title>
          </AboutTop>
        </AboutWrapper>

        <Line className={"animate"} />
        <ContentWrapper>
          <ParagraphWrapper>
            <BigParagraph className="intro-text">
              <AnimateBody text={bigParagraph} />
            </BigParagraph>
          </ParagraphWrapper>
          <WrapperReel>
            <NewThing>
              <Body>check our showreel</Body>
              <ReelWrapperAbout>
                <video autoPlay muted loop playsInline>
                  <source src={reel} type="video/mp4" />
                </video>
                <PlayButton
                  onClick={(e) => {
                    setOpenVid(true);
                  }}
                >
                  Play <PlayButtonArrow />{" "}
                </PlayButton>
              </ReelWrapperAbout>
            </NewThing>
          </WrapperReel>
          {/* <Since>since 2017</Since> */}
          <IntroText className="intro-text">
            <AnimateBody text={smallParagraph} />
          </IntroText>
        </ContentWrapper>
      </TopSectionWrapper>
    </>
  );
};

export default Awards;
